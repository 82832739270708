import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker.js";
import router from "./router";
import store from "./store";
import axios from "axios";
import "./registroGlobalComponents";
import "./vuePluginsConfig";
import vuetify from "./plugins/vuetify";
import "shepherd.js/dist/css/shepherd.css";
import "@/scss/shepherdCustom.scss";
import { URL_BASE } from "@/constants/base";
import moment from "moment";
import { errorCompose } from "./tools";
/*import { makeServer } from "@/miragejs/server";

if (process.env.NODE_ENV === "development") {
  makeServer();
}
*/

export const bus = new Vue();

if (!String.prototype.includes) {
  String.prototype.includes = function() {
    "use strict";
    return String.prototype.indexOf.apply(this, arguments) !== -1;
  };
}

Vue.config.productionTip = false;
Vue.config.errorHandler = function(err, vm, info) {
  window.console.error(err, vm, info);
};

Vue.directive("formata-moeda", {
  bind: function(el, binding) {
    let handler = function(e) {
      if (binding.value) {
        e.target.value = binding.value.toLocaleString("pt-BR", {
          minimumFractionDigits: 2
        });
      }
      el.removeEventListener("input", handler);
    };
    el.addEventListener("input", handler);
  }
});

var appvue = new Vue({
  data() {
    return {
      money: {
        precision: 2,
        decimal: ",",
        thousands: ".",
        masked: false
      }
    };
  },
  methods: {
    errorTratamento(error) {
      this.errorModal(errorCompose(error));
    },
    unit: uni => {
      console.log(typeof uni + " : " + uni);
    },
    compare: (val1, val2) => {
      let tipagem = val1 === val2;
      let valores = val1 == val2;
      console.log("type:" + tipagem, "valores:" + valores);
    },
    validaDataNascimento(dataNasc) {
      if (dataNasc && dataNasc.length === 10) {
        let data = moment(dataNasc, "DD/MM/YYYY");
        if (data.isValid()) {
          if (data.isBefore(moment())) {
            return true;
          }
        }
      }
      return false;
    },
    validaData(dataRecebida, mask) {
      if (!mask) {
        mask = "DD/MM/YYYY";
      }
      if (dataRecebida && dataRecebida.length == 10) {
        let data = moment(dataRecebida, mask);
        return data.isValid();
      }
      return false;
    },
    consultaCEP(valor) {
      var cep = valor.replace(/\D/g, "");
      if (cep !== "") {
        var validacep = /^[0-9]{8}$/;
        return validacep.test(cep);
      } else {
        return false;
      }
    },
    converteValor(valorStr) {
      // eslint-disable-next-line no-extra-boolean-cast
      if (
        valorStr &&
        valorStr !== null &&
        valorStr !== undefined &&
        valorStr !== "" &&
        typeof valorStr === "string"
      ) {
        // return Number(valorStr.replace(',', '.').replace(/[^0-9.-]+/g, ''))
        return Number(
          valorStr
            .replaceAll(".", "")
            .replace(",", ".")
            .replace(/[^0-9.-]+/g, "")
        );
      }
      return valorStr;
    },
    getMoney() {
      return {
        decimal: ",",
        thousands: ".",
        prefix: "R$ ",
        suffix: " #",
        precision: 2,
        masked: false /* doesn't work with directive */
      };
    },
    ATUALIZA_DADOS() {
      this.$store.dispatch("aluno/RELOAD_ALL");
    },
    errorModal(mensagem) {
      this.$store.commit("global/setModal", {
        mensagem: mensagem,
        tipo: "erro",
        toggle: true
      });
    },
    sucessoModal(mensagem) {
      this.$store.commit("global/setModal", {
        mensagem: mensagem,
        tipo: "sucesso",
        toggle: true
      });
    },
    infoModal(mensagem) {
      this.$store.commit("global/setModal", {
        mensagem: mensagem,
        tipo: "informacao",
        toggle: true
      });
    },
    closeModal() {
      this.$store.commit("global/setModal", {
        toggle: false
      });
    },
    setModal(payload) {
      if (payload.toggle) {
        this.$store.commit("global/setModal", {
          mensagem: payload.mensagem,
          tipo: payload.tipo,
          toggle: payload.toggle
        });
      } else {
        this.$store.commit("global/setModal", {
          mensagem: "",
          tipo: "info",
          toggle: false
        });
      }
    },
    validaCNPJ(payload) {
      if (!payload) return false;
      let cnpj = payload.replace(/[^\d]+/g, "");
      if (cnpj == "") return false;
      if (cnpj.length != 14) return false;
      // Elimina CNPJs invalidos conhecidos
      if (
        cnpj == "00000000000000" ||
        cnpj == "11111111111111" ||
        cnpj == "22222222222222" ||
        cnpj == "33333333333333" ||
        cnpj == "44444444444444" ||
        cnpj == "55555555555555" ||
        cnpj == "66666666666666" ||
        cnpj == "77777777777777" ||
        cnpj == "88888888888888" ||
        cnpj == "99999999999999"
      )
        return false;

      // Valida DVs
      let tamanho = cnpj.length - 2;
      let numeros = cnpj.substring(0, tamanho);
      let digitos = cnpj.substring(tamanho);
      let soma = 0;
      let pos = tamanho - 7;
      for (let i = tamanho; i >= 1; i--) {
        soma += numeros.charAt(tamanho - i) * pos--;
        if (pos < 2) pos = 9;
      }
      let resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
      if (resultado != digitos.charAt(0)) return false;

      tamanho = tamanho + 1;
      numeros = cnpj.substring(0, tamanho);
      soma = 0;
      pos = tamanho - 7;
      for (let i = tamanho; i >= 1; i--) {
        soma += numeros.charAt(tamanho - i) * pos--;
        if (pos < 2) pos = 9;
      }
      resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
      if (resultado != digitos.charAt(1)) return false;
      return true;
    },
    validateCPF(payload) {
      let Soma;
      let Resto;
      let strCPF = String(payload);
      strCPF = strCPF.replaceAll(".", "");
      strCPF = strCPF.replaceAll("-", "");
      Soma = 0;
      if (strCPF == "00000000000") return false;

      for (let i = 1; i <= 9; i++)
        Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (11 - i);
      Resto = (Soma * 10) % 11;

      if (Resto == 10 || Resto == 11) Resto = 0;
      if (Resto != parseInt(strCPF.substring(9, 10))) return false;

      Soma = 0;
      for (let i = 1; i <= 10; i++)
        Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (12 - i);
      Resto = (Soma * 10) % 11;

      if (Resto == 10 || Resto == 11) Resto = 0;
      if (Resto != parseInt(strCPF.substring(10, 11))) return false;
      return true;
    }
  },
  computed: {
    CompSmAndUp() {
      return this.$vuetify.breakpoint.smAndUp;
    },
    CompSmAndDown() {
      return this.$vuetify.breakpoint.smAndUp;
    }
  },
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount("#app");

axios.defaults.baseURL = URL_BASE;

axios.interceptors.response.use(
  function(response) {
    return response;
  },
  function(error) {
    if (error.response.status === 401) {
      //let atualPath = appvue.$route.path;
      localStorage.removeItem("JWT_APPDATURMA_ALUNO/token");
      localStorage.removeItem("JWT_APPDATURMA_ALUNO/type");
      appvue.errorModal("Token expirado, efetue o login.");
      appvue.$router.push("/aluno/login");
      window.console.log("Não autorizado");
    }
    return Promise.reject(error.response);
  }
);

axios.interceptors.request.use(
  config => {
    var tokenID;
    let firewall = appvue.$route.path;
    if (firewall == "/site/home") {
      return config;
    } else {
      if (localStorage.getItem("JWT_APPDATURMA_ALUNO/token")) {
        tokenID = localStorage.getItem("JWT_APPDATURMA_ALUNO/token");
      }
      if (
        config.baseURL === URL_BASE &&
        !config.headers.Authorization &&
        localStorage.getItem("JWT_APPDATURMA_ALUNO/token")
      ) {
        config.headers.Authorization = `Bearer ${tokenID}`;
      }
      return config;
    }
  },
  error => Promise.reject(error)
);

export { appvue };
