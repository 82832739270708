import store from "@/store/index.js";

function guardTurma(to, from, next) {
  // REFATORAR ISSO.
  const isAdmin = store.getters["aluno/userIsAdmin"];
  if (isAdmin) {
    next();
    return;
  }
  let turmaAtiva = store.getters["aluno/getTurmaAtiva"];
  if (!store.getters["aluno/getTurmas"]) next("/aluno/dashboard/perfil");
  let ExisteTurmas = store.getters["aluno/getTurmas"];
  if (store.getters["aluno/getTurmas"]) {
    ExisteTurmas = store.getters["aluno/getTurmas"].length > 0;
  }
  if (
    !turmaAtiva ||
    turmaAtiva === "" ||
    turmaAtiva === undefined ||
    turmaAtiva === null
  )
    next("/aluno/dashboard/perfil");
  if (!ExisteTurmas) next("/aluno/dashboard/perfil");
  if (turmaAtiva == undefined || turmaAtiva == null || turmaAtiva == "") {
    next();
  } else next();
}

export default {
  name: "aluno",
  path: "/aluno",
  component: () =>
    import(/* webpackChunkName: "alunoDashboard" */ "@/views/aluno/index.vue"),
  redirect: "/aluno/login",
  children: [
    {
      path: "login",
      name: "login-aluno",
      component: () =>
        import(/* webpackChunkName: "login" */ "@/views/aluno/login.vue")
    },
    {
      path: "completar-cadastro/:codigo",
      name: "completar-cadastro",
      component: () =>
        import(
          /* webpackChunkName: "login" */ "@/views/aluno/completarCadastro/index.vue"
        )
    },
    {
      path: "dashboard",
      name: "dashboard-aluno",
      component: () =>
        import(
          /* webpackChunkName: "alunoDashboard" */ "@/views/aluno/dash/index.vue"
        ),
      redirect: "/aluno/dashboard/inicio",
      beforeEnter: (to, from, next) => {
        if (!localStorage.getItem("JWT_APPDATURMA_ALUNO/token")) {
          next("/aluno/login");
        } else {
          next();
        }
      },
      children: [
        {
          path: "reunioes",
          name: "Controle de Presença",
          component: () =>
            import("@/views/aluno/dash/content/reunioes/vRouterReunioes.vue")
        },
        {
          path: "programa-custo-zero",
          name: "Programa Custo Zero",
          component: () =>
            import(
              /* webpackChunkName: "alunoDashboard" */ "@/views/aluno/dash/content/programaCustoZero/RouterProgramaCustoZero.vue"
            ),
          beforeEnter: (to, from, next) => {
            if (store.getters["global/getModuleFCZ"]) {
              next();
            } else {
              next("/aluno/dashboard/perfil");
            }
          }
        },
        {
          path: "extrato",
          name: "ExtratoPorEvento",
          component: () =>
            import(
              /* webpackChunkName: "alunoDashboard" */ "@/views/aluno/dash/content/extratoPorEvento/index.vue"
            )
        },
        {
          path: "plano-de-arrecadacao",
          name: "plano-de-arrecadacao",
          component: () =>
            import(
              /* webpackChunkName: "alunoDashboard" */ "@/views/aluno/dash/content/plano/index.vue"
            )
        },
        {
          path: "cotas-individuais",
          name: "cotas-individuais",
          component: () =>
            import(
              /* webpackChunkName: "alunoDashboard" */ "@/views/aluno/dash/content/cotas/index.vue"
            )
        },
        {
          path: "inicio",
          name: "inicio-aluno",
          component: () =>
            import(
              /* webpackChunkName: "alunoDashboard" */ "@/views/aluno/dash/content/dashboard/index.vue"
            )
        },
        {
          path: "perfil",
          name: "perfil-aluno",
          component: () =>
            import(
              /* webpackChunkName: "alunoDashboard" */ "@/views/aluno/dash/content/perfil/index.vue"
            )
        },
        {
          path: "turma",
          name: "turma-dados",
          component: () =>
            import(
              /* webpackChunkName: "alunoDashboard" */ "@/views/aluno/dash/content/turma/index.vue"
            ),
          beforeEach: (to, from, next) => {
            guardTurma(to, from, next);
          }
        },
        {
          path: "fornecedores",
          name: "DashFornecedores",
          component: () =>
            import(
              /* webpackChunkName: "alunoDashboard" */ "@/views/aluno/dash/content/fornecedores/index.vue"
            )
        },
        {
          path: "depositos",
          name: "depositos",
          component: () =>
            import(
              /* webpackChunkName: "alunoDashboard" */ "@/views/aluno/dash/content/depositos/index.vue"
            )
        },
        {
          path: "solicitacao-pagamento",
          name: "solicitacaoDePagamento",
          component: () =>
            import(
              /* webpackChunkName: "alunoDashboard" */ "@/views/aluno/dash/content/solicitacaoDePagamento"
            )
        },
        {
          path: "pagamento-parcelas",
          name: "pagamentoParcelas",
          component: () =>
            import(
              /* webpackChunkName: "alunoDashboard" */ "@/views/aluno/dash/content/pagamentoParcelas/index.vue"
            ),
          beforeEnter: (to, from, next) => {
            /*let opt = guardTurma();
            next(opt);*/
            guardTurma(to, from, next);
          }
        },
        {
          path: "alunos",
          name: "alunosDaTurma",
          component: () =>
            import(
              /* webpackChunkName: "alunoDashboard" */ "@/views/aluno/dash/content/alunos/index.vue"
            ),
          beforeEnter: (to, from, next) => {
            guardTurma(to, from, next);
          }
        },
        {
          path: "ajuda",
          name: "dashAjuda",
          component: () => import("@/views/aluno/dash/content/ajuda/index")
        },
        {
          path: "central-de-atendimento",
          name: "centralDeAtendimento",
          component: () =>
            import(
              /* webpackChunkName: "alunoDashboard" */ "@/views/aluno/dash/content/atendimento/index.vue"
            )
        },
        {
          path: "*",
          name: "404",
          component: () =>
            import(
              /* webpackChunkName: "alunoDashboard" */ "@/components/404.vue"
            )
        }
      ]
    }
  ]
};
