export const errorCompose = error => {
  if (error) {
    if (process.env.NODE_ENV === "development") {
      console.error(error);
    }
    if (error.data) {
      if (error.data.message) {
        return error.data.message;
      } else {
        return error.data;
      }
    } else {
      return error;
    }
  } else {
    if (process.env.NODE_ENV === "development") {
      console.error("Erro não definido");
    }
  }
};

export const formataContato = telefone => {
  return telefone
    .replace(/(\d{2})(\d)/, "($1) $2")
    .replace(/(\d{5})(\d)/, "$1-$2");
};
